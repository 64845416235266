import React from 'react';

import Layout from '../components/layout/layout';
import Contact from "../components/contact/contact";

const ContactPage = () => {
  return (
    <Layout>
      <Contact />
    </Layout>
  )
}

export default ContactPage
